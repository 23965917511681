<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/countries' }">Countries</el-breadcrumb-item>
                  <el-breadcrumb-item>Things to do</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
  
            <h3><b>Things to do:</b></h3>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Name"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
             
            <div>
              <AddExperienceDialog @re-fresh="getExperiences" />
            </div>
          </div>
          <hr />

          <div
            v-loading="loading || is_changing"
            class="table-responsive mb-5"
          >
            <data-tables
              :data="experiences"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="isLoading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getExperiences"
                  v-else-if="!isLoading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Things To Do Now. Please click here to retry
                </div>
                <div v-if="!isLoading && !loadingError">No Things To Do</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Photo"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="75px"
              >
                <template slot-scope="scope">
                  <img
                    :src="scope.row.image"
                    style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                  >
                </template>
              </el-table-column>

              <el-table-column
                label="Experience"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span> {{ scope.row.title }} </span><br />
                  <small>Add On: {{ formattedDate(scope.row.createdDate) }} </small><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Status"
                label-class-name="text-center"
                class-name="text-center"
                width="100px"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex;">
                    <el-tooltip
                      :content="scope.row.is_active ? 'Active' : 'Deactivated'"
                      placement="top"
                    >
                      <el-switch
                        v-model="scope.row.is_active"
                        @change="switchStatus(scope.row.isActive, scope.row.id)"
                        active-color="#13ce66"
                        inactive-color=""
                      >
                      </el-switch>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Edit"
                      placement="top"
                    >
                      <el-button
                        type="warning"
                        :disabled="false"
                        @click="showEditExperienceData(scope.row)"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        :disabled="false"
                        @click="deleteExperience(scope.row.id)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

    <EditExperienceDialog
      :showExperienceEditDialog="showExperienceEditDialog"
      :experienceData="experienceData"
      @closeEditExperienceDialog="closeEditExperienceDialog"
      @re-fresh="getExperiences"
    />

  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import AddExperienceDialog from "../components/dialogs/add-experience-dialog.vue";
import EditExperienceDialog from "../components/dialogs/edit-experience-dialog.vue";
import { format, parseISO } from "date-fns";
export default {
  components: {
    ScaleOut,
    AddExperienceDialog,
    EditExperienceDialog,
  },

  data() {
    return {
      loading: false,
      isLoading: false,
      is_changing: false,
      showExperienceEditDialog: false,
      experiences: [],
      experienceData: {},
      decoded_token: {},
      decoded_token2: {},
      first_name: "",

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "fullname",
          value: "",
        },
      ],
      loadingError: false,
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getExperiences();
  },

  methods: {
    showEditExperienceData(experienceData) {
      this.showExperienceEditDialog = true;
      this.experienceData = experienceData;
    },

    closeEditExperienceDialog() {
      this.showExperienceEditDialog = false;
    },

    async getExperiences() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`experiences/${this.$route.params.countryID}`);
        if (request.data.success) {
          this.experiences = request.data.experiences;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch experiences now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async deleteExperience(id) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Experience. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(`experiences/${id}`);
            if (
              request.data.success &&
              request.data.message === "EXPERIENCE_DELETED_SUCCESSFULLY"
            ) {
              this.getExperiences();
              this.$notify({
                title: "Success",
                message: "Experience deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete the Experience, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },

    

    async switchStatus(activation_status, id) {
      try {
        this.is_changing = true;
        let request = await this.$http.patch(
          `experiences/change_is_active_status/${id}`, {
            is_active: activation_status,
          }
        );
        if (
          request.data.success &&
          request.data.message == "EXPERIENCE_IS_ACTIVE_STATUS_CHANGED_SUCCESSFULLY"
        ) {
          this.getExperiences();
          this.showSuccessMessage("Success", );
              return this.$notify({
                title: "Success",
                message: "Experience status changed",
                type: "success",
              });
        } else {
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.is_changing = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to change Experience status",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.is_changing = false;
      }
    },

  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>